<template>
  <div style="height: 100%;">
    <header-selete placeholder="公司名称搜索" fixed @submit="resetList" ref="headerSelete"/>
    <xia-la-load ref="xiaLa" @load="getLoad">
      <div class="gong-si" v-for="vo in dataList" :key="vo.g_id">
        <div class="gong-si-logo">
          <van-image class="gong-si-logo-image" height="70" width="70" :src="vo.logo"/>
        </div>
        <div class="gong-si-info">
          <div class="gong-si-name van-ellipsis">{{ vo.g_name }}</div>
          <div class="gong-si-type">认证类型：公司</div>
          <div class="gong-si-edit">
            <div class="van-ellipsis gong-si-edit-name">
              地址：{{ vo.g_address }}
            </div>
            <div class="gong-si-edit-btn">
              <a href="javascript:" @click="addGuanLian(vo.g_id)">申请关联</a>
            </div>
          </div>
        </div>
      </div>
    </xia-la-load>
    <footer-nav fixed/>
  </div>
</template>

<script>
import HeaderSelete from "../open/headerSelete";
import FooterNav from "../open/footerNav";
import XiaLaLoad from "../../components/list/XiaLaLoad";
import http from "../../api/http";
import {hidden} from "../../api/GongJu";
import din_shi from "../../api/din_shi";

export default {
  name: 'JiaRuGongSi',
  components: {XiaLaLoad, FooterNav, HeaderSelete},
  mixins: [],
  data() {
    return {
      where: {},
      dataList: []
    }
  },
  mounted() {
  },
  methods: {
    resetList() {
      this.$refs.xiaLa.resetData()
    },
    getLoad(page) {
      this.where.page = page
      this.where.keyword = this.$refs.headerSelete.getKeyword()
      http.get('/gzhphp/user_xiang_mu/getGuanLianGongSiList', {params: this.where}).then(da => {
        this.$refs.xiaLa.setLoadSuccess2(da).then(({page, item}) => {
          item = item.map(n => {
            n.g_address = hidden(n.g_address, 6, 0)
            return n
          }) //修改数据
          if (page === 1) this.dataList = item
          else this.dataList = [...this.dataList, ...item]
        })
      })
    },
    addGuanLian(g_id) {
      // console.log(g_id)
      http.post('/gzhphp/user_xiang_mu/addGuanLian', {g_id: g_id}).then(da => {
        if (da.code === 1) {
          din_shi.success(da.msg)
        }
      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.gong-si {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: stretch;
  margin: 10px;
  background-color: @white;
  box-shadow: @yin-ying;
  padding: 10px;
  border-radius: 10px;

  .gong-si-logo {
    width: 80px;

    .gong-si-logo-image {
      border-radius: 5px;
      overflow: hidden;
    }
  }

  .gong-si-info {
    flex: 1;
    overflow-x: hidden;

    .gong-si-edit, .gong-si-type {
      color: @gray-6;
    }

    .gong-si-name {
      font-weight: bold;
      font-size: 16px;
    }

    .gong-si-edit {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: flex-start;
      justify-content: space-between;

      .gong-si-edit-btn {
        width: 80px;
        text-align: right;
      }

      .gong-si-edit-name {
        flex: 1;
      }
    }

    .gong-si-name, .gong-si-type {
      margin-bottom: 4px;

    }

  }

}
</style>
